.hubPageHeader {
  padding-top: var(--spacing-5);

  &-contentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: var(--spacing-10) 0;

    h1 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  img {
    height: 183.35px;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  img {
    margin: 0 calc(var(--spacing-5) * -1);
    max-width: none;
    width: calc(100% + var(--spacing-5) * 2);
  }
}

@media (--tablet) {
  .hubPageHeader {
    padding-bottom: var(--spacing-6);

    &-contentContainer {
      padding: var(--spacing-12) 0;
    }
    img {
      height: 328px;
    }
    img {
      margin: 0 calc(var(--spacing-6) * -1);
      width: calc(100% + var(--spacing-6) * 2);
    }
  }
}

@media (--tablet-l) {
  .hubPageHeader {
    img {
      margin: 0 calc(var(--spacing-8) * -1);
      width: calc(100% + var(--spacing-8) * 2);
    }
  }
}

@media (--desktop) {
  .hubPageHeader {
    padding-bottom: var(--spacing-10);

    &-contentContainer {
      padding: var(--spacing-16) 0;
    }
    img {
      margin: 0;
      width: calc(100% + var(--spacing-8));
    }
  }
}
@media (--desktop-l) {
  .hubPageHeader {
    img {
      margin: 0;
      width: 100%;
    }
  }
}
