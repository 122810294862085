.row {
  background-color: var(--color-heycar-mint-50);
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--spacing-6) var(--spacing-4) var(--spacing-12);
}

.date {
  margin-bottom: var(--spacing-3);
}

.heading {
  margin-top: 0;
  margin-bottom: var(--spacing-2);
}

.subheading {
  margin-top: 0;
  margin-bottom: var(--spacing-7);
}

.button {
  margin-bottom: var(--spacing-1);
}

@media (min-width: --tablet) {
  .content {
    padding: var(--spacing-9) var(--spacing-6) var(--spacing-14);
  }
}

@media (min-width: --desktop) {
  .mostRecentFeaturedArticle {
    padding-left: var(--spacing-8) !important;
    padding-right: var(--spacing-8) !important;
  }
  .content {
    padding: var(--spacing-16);
  }
}

@media (min-width: --desktop-l) {
  .mostRecentFeaturedArticle {
    padding-left: var(--spacing-16) !important;
    padding-right: var(--spacing-16) !important;
  }
}
