.wrapper {
  & h1 {
    margin: 0 !important;
  }
  margin: -24px 0;

  @media (--tablet) {
    margin: 40px 0;
  }
  @media (--tablet-l) {
    margin: -40px 0;
  }

  .dealersSection {
    display: grid;
    padding: 0;
    list-style: none;
    gap: var(--spacing-6);
    margin: 0;
    padding-top: var(--spacing-6);
    grid-template-columns: repeat(2, 1fr);

    @media (--mobile-s) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(4, 1fr);
    }

    & .capitalizeLabel {
      text-transform: capitalize;
    }

    & a {
      text-decoration: none;
      color: inherit;
      display: flex;
      flex-direction: column;
    }

    .dealersCard {
      list-style: none;
      border: 1px solid var(--Colour-Neutral-100, #e6e6e6);

      @media (--tablet) {
        width: 100%;
      }

      & .headerWithLogo {
        display: flex;
        align-items: center;
        background-color: var(--color-tertiary-200);
        gap: var(--spacing-2);
        padding: var(--spacing-5) var(--spacing-4);

        & h2 {
          margin: 0;
          line-height: 28.78px;
        }
      }

      & .headerWithIcon {
        display: flex;
        align-items: center;
        gap: var(--spacing-2);
        padding: 0 var(--spacing-4);

        &:last-child {
          padding-top: var(--spacing-3);
          padding-bottom: var(--spacing-5);
        }

        &:not(:last-child) {
          padding-top: var(--spacing-5);
        }
      }
    }
  }
}

& [data-tenant='rac'] {
  && .dealersCard {
    border: 1px solid var(--color-rac-grey-10);
  }

  && .headerWithLogo {
    background: #f7f7f7;
  }
}
