.taggedArticles {
  padding-top: var(--spacing-6);
}

.title {
  margin: 0 0 var(--spacing-6);
}

.article {
  margin-bottom: var(--spacing-5);
}

.mobAd {
  margin-bottom: var(--spacing-5);

  @media (--tablet) {
    display: none;
    flex: 0 1 0;
    padding: 0;
  }
}

@media (--tablet) {
  .taggedArticles {
    padding-top: var(--spacing-6);
  }

  .article {
    margin-bottom: var(--spacing-6);
  }
}

@media (--desktop) {
  .taggedArticles {
    padding-top: var(--spacing-8);

    justify-content: flex-start;
  }

  .title {
    margin-bottom: var(--spacing-8);
  }

  .article {
    margin-bottom: var(--spacing-8);
  }
}
